<template>
  <div class="thank-you container">
    <div class="text-center">
      <h2 class="title">{{ blok.title }}</h2>
      <p class="description">{{ blok.description }}</p>
    </div>
    <nuxt-img
      quality="90"
      format="webp"
      placeholder
      :src="blok.image.filename"
      alt="thank you image"
      class="img"
    />
  </div>
</template>
<script>
export default {
  name: "ThankYou",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.thank-you {
  @apply my-20;
}
.title {
  font: normal normal bold 55px/55px Montserrat;
  @media only screen and (max-width: 1024px) {
    font: normal normal bold 45px/55px Montserrat;
  }
  @media only screen and (max-width: 768px) {
    font: normal normal bold 35px/55px Montserrat;
  }
}
.description {
  font: normal normal bold 28px/35px "Franklin Gothic Book", sans-serif;
  max-width: 700px;
  margin: auto;
  @apply my-10;
  @media only screen and (max-width: 1024px) {
    font: normal normal bold 24px/35px "Franklin Gothic Book", sans-serif;
  }
  @media only screen and (max-width: 768px) {
    font: normal normal bold 20px/35px "Franklin Gothic Book", sans-serif;
  }
}
.img {
  max-width: 550px;
  width: 100%;
  height: auto;
  margin: auto;
  object-fit: cover;
  border-radius: 0.75rem;
  @media only screen and (max-width: 1024px) {
    max-width: 400px;
  }
}
</style>
